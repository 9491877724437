html,
body{
  font-family: 'Titillium Web', sans-serif;
  background-color: transparent;
  height: 100%;
  margin:0;

}
a {
  color: blue;
  text-decoration: none; /* no underline */
}
.heading {
  font-weight: 700;
}
.subheading {
  font-weight: 500;
}
.container{
  max-width: 1200px;
  height: '100%';
  margin: 86px auto 0 auto;
  margin-top: -100px;
}
.container-not-centered{
  height: '100%';
  margin: 0 auto 0 auto;
}
.container-noup{
  max-width: 1200px;
  height: '100%';
  margin: 0px auto 0 auto;
}
.container-noup-wider{
  margin: 0 auto !important;
  max-width: 1440px;
  width: 100%;
}
.log-in-container{
  max-width: 1200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  height: '100vh';
}
.nav-container{
  margin: 0px 10% 0px 10%;
}
.nav-container svg{
  color: #fff;
}
.all-container svg{
  color: #fff;
}
.nav-toggle{
  position: 'absolute';
  bottom:0;
  left:0;
}
a{
  text-decoration: none;
}
.navbar{
  margin: 0px auto 0 auto;
}

.diagonal-box {
	background-image: linear-gradient(45deg, #033ab1, #00e1ff);
  transform: skewY(-4deg);
  width: 100%;
  height: 300px;
  background-position: 'center';
  background-size: 'cover';
  background-repeat: 'no-repeat';
  margin-top: -20px;
  position: relative;
} 
.background-image {
  width: 100%;
  height: 350px;
  position: relative;
}
.background-image-home {
  width: 100%;
  position: relative;
}
.background-image-full {
  background-image: url(./images/flat.jpg); /* fallback */
  position:'relative';
  width: 100vw;
  height: 100vh;
  margin-top:-74px;
  overflow: hidden;
}
.content-image{
  width:100%;
  margin: 0 auto; 
  margin-top: 60px;
  position: absolute;
}
.content { 	
	width:100%;
  margin: 0 auto; 
  transform: skewY(4deg);
  position: absolute;
}
.normal-content{
  width:100%
}
.search .ui.input{
  color: rgb(255 255 255 / 60%)
}
.search .ui.input>input{
  background: #f1f1f100;
  color: rgb(255 255 255 / 95%);
  border: 2px solid rgb(255 255 255 / 30%);
}
.search .ui.input>input:active{
  background: #f1f1f11f;
  color: rgb(255 255 255 / 100%);
  border: 2px solid #fff;
}
.search .ui.input>input:focus{
  background: #f1f1f11f;
  color: rgb(255 255 255 / 100%);
  border: 2px solid #fff;
}

.search.searchGrey .ui.input{
  color: #bebebe;
}
.search.searchGrey .ui.input>input{
  background: #23232300;
  color:#272727;
  border: 2px solid #e6e6e6;
}
.search.searchGrey .ui.input>input:active{
  background:#23232300;
  color: #272727;
  border: 2px solid rgba(42, 134, 255, 0.774);
}
.search.searchGrey .ui.input>input:focus{
  background: #23232300;
  color: #272727;
  border: 2px solid  rgba(42, 134, 255, 0.329);
}

.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  border: 1px green solid;
}
 
.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}
:focus{
  outline: 'none'
}