.ui.basic.button.tertiary {
    box-shadow: none !important;
    padding: '0px';
    color: '#ff47ac';
    background-color: #0fa158;
  }

 /*.ui.white.button.tertiary a:hover{
    color: 'white';
    text-emphasis: 2px;
    text-decoration-color: white;
} */
.ui.diff.button.tertiary a {
    text-decoration: none;
    position: relative;
    display: block;
    padding: 4px 0;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: color 0.4s, background-color 0.1s;
    color: rgb(148, 148, 148);
  }
  .ui.diff.button.tertiary a:hover {
    color: #1522ccea;
  }
  
  .ui.diff.button.tertiary a::before {
    background-color: #0fa158;
    transition: color 0.1s, -webkit-transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  }
  
  .ui.diff.button.tertiary a:active::before {
    background-color: #979292;
  }
  
  .ui.diff.button.tertiary a:hover::before,
  .ui.diff.button.tertiary a:focus::before {
    -webkit-transform-origin: center top;
            transform-origin: center top;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }