.navbar {
    border-bottom: solid 1px #EBEBEB10;
    background-color: rgb(22, 24, 27);
  }
  .bottom-tab-nav {
    border-top: solid 1px #EBEBEB10;
  }
  .nav-link,
  .bottom-nav-link {
    color: #ffffff;
  }
  .bottom-nav-link.active {
    color: #ffffff;
  }
  .bottom-tab-label {
    font-size: 12px;
  }