.ui.focus.search .ui.input>input{
    background: '#c56363';
    color: 'rgb(255 255 255 / 87%)';
  }

  .ui.focus.search .ui.fluid.icon.input .ui.input>input{
    background: '#c56363';
    color: 'rgb(255 255 255 / 87%)';
  }
  .input.ui.input>input{
    background: '#c56363';
    color: '#c56363';
  }