/* .planeWrapper {
    transition: 20s;
    left: -80px;
    top: 400px;
}
.planeWrapper.is-moving {
    left: 800px;
    top: -60px
} */

.planeWrapper {
    animation: plane1animation 20s infinite;

    position: absolute;
    left: 440px;
    top: 230px;

}

.planeWrapper2 {
    animation: plane2animation 30s linear infinite;

    position: absolute;
    right: -300px;
    top: -200px;

}

@keyframes plane1animation {
    0% {
        left: 440px;
        top: 230px;
    }
    59% {
        left: 1300px;
        top: -200px;
    }
    60% {
        left: -150px;
        top: -150px;
    }
    61% {
        left: -210px;
        top: 600px;
    }
}

@keyframes plane2animation {
    0% {
        right: -300px;
        top: -200px;
    }
    98% {
        right: 2000px;
        top: 1300px;
    }
    99% {
        right: -800px;
    }
}
  